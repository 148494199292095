button {
  
	color: inherit;
	border: none;
  margin: 10px auto;
  background-color: black;
  color: white;
  padding: 10px;

	outline: inherit;
}

* { font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

input.code {
  margin-top: 10px;
  margin-right: 10px;
  padding: 7px;
  font-size: 16px;
}

.question {
  font-style: italic;
  color: rgb(128, 0, 94);
}

.saving-no {
  padding-top: 5px;

  color: rgb(255, 0, 0);
}

span.right {
  float: right;
}

.total {
  font-weight: 700;
}